<template>
  <div class="projectChart">
    <el-row :gutter="20">
      <el-col :span="12">
        <el-card style="width: 100%">
          <div slot="header" class="clearfix">
            <span>关节活动度</span>
          </div>
          <echart
            chartID="radchartLeg1"
            ref="radchartLeg1"
            :chartHeight="height"
          ></echart>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card style="width: 100%">
          <div slot="header" class="clearfix">
            <span>功能动作</span>
          </div>
          <echart
            chartID="radchartLeg2"
            ref="radchartLeg2"
            :chartHeight="height"
          ></echart>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import echart from "@/components/echart";
import * as echarts from "echarts";
import { saveCheckImage, invertFootPressureImages } from "@/api/check/check.js";
export default {
  components: { echart },
  props: {
    rad1: {
      default: {
        indicators: [],
        datas: [],
      },
    },
    rad2: {
      default: {
        indicators: [],
        datas: [],
      },
    },
    footPressure: "",
    height: "600px",
    invert: false,
    checkKey: "",
  },
  watch: {
    rad1: {
      handler(v) {
        if (v) {
          this.loadRad1();
        }
      },
      deep: true,
      immediate: true,
    },
    rad2: {
      handler(v) {
        if (v) {
          this.loadRad2();
        }
      },
      deep: true,
      immediate: true,
    },
    checkKey: {
      handler(v) {
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      images: [],
    };
  },
  methods: {
    //显示雷达数据
    loadRad1() {
      if (this.rad1) {
        let indicators = this.rad1.indicators;
        let datas = this.rad1.datas;
        this.$refs.radchartLeg1.showChart(this.getRadOption(indicators, datas));
      }
    },
    loadRad2() {
      if (this.rad2) {
        let indicators = this.rad2.indicators;
        let datas = this.rad2.datas;
        this.$refs.radchartLeg2.showChart(this.getRadOption(indicators, datas));
      }
    },
    getRadOption(indicators, datas) {
      let radOption = {
        color: ["#ff6a42"],
        radar: {
          startAngle: 90,
          indicator: indicators,
          radius:'60%',
          name: {
            textStyle: { color: "#A0522D", fontSize: 16 },
          },
        },
        series: [
          {
            type: "radar",
            emphasis: {
              lineStyle: {
                width: 4,
              },
            },
            data: [
              {
                symbol: "rect",
                symbolSize: 16,
                lineStyle: {
                  type: "dashed",
                },
                value: datas,
                label: {
                  show: true,
                  position: "inside",
                  distance: 5,
                  fontSize: 16,
                  fontWeight: "bolder",
                  color: "#ffffff",
                  formatter: function (params) {
                    return params.value;
                  },
                },
                areaStyle: {
                  color: new echarts.graphic.RadialGradient(0.1, 0.6, 1, [
                    {
                      color: "rgba(255, 145, 124, 0.2)",
                      offset: 0,
                    },
                    {
                      color: "rgba(255, 145, 124, 0.9)",
                      offset: 1,
                    },
                  ]),
                },
              },
            ],
          },
        ],
      };
      return radOption;
    },
    getImageData1() {
      let imageData = this.$refs.radchartLeg1.getImageData();
      let param = { type: "KneeHurt1", image: imageData };
      return param;
    },
     getImageData2() {
      let imageData = this.$refs.radchartLeg2.getImageData();
      let param = { type: "KneeHurt2", image: imageData };
      return param;
    },
  },
  mounted() {
    this.loadRad1();
    this.loadRad2();
  },
};
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
.projectChart {
  width: 100%;
  .images {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    .item {
    }
  }
}
</style>